




























































































































import { Metrics } from '@/features/core/util/metrics';
import { formatMetricValue } from '@/features/core/util/metric-formatters';
import { isDef } from '@/util/lang';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppActAquaTreeNodeViewQuery,
  AppActAquaTreeNodeViewQueryVariables,
} from './__generated__/AppActAquaTreeNodeViewQuery';
import { AppActAquaTreeNodeViewActAquaSystemFragment } from './__generated__/AppActAquaTreeNodeViewActAquaSystemFragment';
import query from './view.gql';
import { DateTime } from 'luxon';

type PipeSpotWithMetrics = Omit<AppActAquaTreeNodeViewActAquaSystemFragment['pipeSpots'][number], 'metrics'> & {
  metrics: Metrics;
};

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: TreeNodeView): AppActAquaTreeNodeViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeView extends Vue {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppActAquaTreeNodeViewQuery['treeNodes'];
  private readonly formatMetricValue = formatMetricValue;

  private get actAquaSystem(): AppActAquaTreeNodeViewActAquaSystemFragment | undefined {
    const treeNode = this.treeNodes?.first;
    if (treeNode === undefined || !('actAquaSystems' in treeNode)) {
      return undefined;
    }

    return treeNode.actAquaSystems.items[0];
  }

  private get pipeSpots(): PipeSpotWithMetrics[] {
    return (
      this.actAquaSystem?.pipeSpots.map((spot) => ({
        ...spot,
        metrics: Metrics.create(spot.metrics.map(({ latest }) => latest)),
      })) ?? []
    );
  }

  private get pipeTemperatures(): number[] {
    return this.pipeSpots.map((spot) => this.retrievePipeTemperature(spot)).filter(isDef);
  }

  private get lowestPipeTemperature(): number | undefined {
    return this.pipeTemperatures.reduce<number | undefined>(
      (a, b) => Math.min(a ?? Number.POSITIVE_INFINITY, b),
      undefined,
    );
  }

  private get meanPipeTemperature(): number | undefined {
    return this.pipeTemperatures.length > 0
      ? this.pipeTemperatures.reduce((a, b) => a + b, 0) / this.pipeTemperatures.length
      : undefined;
  }

  private get highestPipeTemperature(): number | undefined {
    return this.pipeTemperatures.reduce<number | undefined>(
      (a, b) => Math.max(a ?? Number.NEGATIVE_INFINITY, b),
      undefined,
    );
  }

  private get reservoirTemperature(): number | undefined {
    return this.pipeSpots
      .map(({ metrics }) => metrics.continuous.meanReservoirTemperature)
      .filter(isDef)
      .sort((a, b) => DateTime.fromISO(a.time).toMillis() - DateTime.fromISO(b.time).toMillis())
      .pop()?.value;
  }

  private get systemFlow(): number | undefined {
    return this.pipeSpots
      .map(({ metrics }) => metrics.continuous.systemFlow)
      .filter(isDef)
      .sort((a, b) => DateTime.fromISO(a.time).toMillis() - DateTime.fromISO(b.time).toMillis())
      .pop()?.value;
  }

  private get pipeCountOk(): number {
    return this.pipeSpots.filter(
      ({ metrics }) => this.determineTemperatureColor(metrics.continuous.meanCirculationTemperature?.value) === 'GREEN',
    ).length;
  }

  private retrievePipeTemperature(spot: PipeSpotWithMetrics): number | undefined {
    return spot.metrics.continuous.meanCirculationTemperature?.value;
  }

  private determineTemperatureColor(temperature: number | undefined): string {
    if (temperature === undefined) {
      return 'GREY';
    }

    if (temperature < 55 || temperature > 63) {
      return 'RED';
    }

    return 'GREEN';
  }
}
