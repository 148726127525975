









import { formatMetricValue } from '@/features/core/util/metric-formatters';
import { NumberProp, StringProp, EnumProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import PipeImage from './pipe.svg?vue';

@Component({ components: { PipeImage } })
export default class Pipe extends Vue {
  @StringProp()
  private readonly label?: string;

  @NumberProp()
  private readonly temperature?: number;

  @EnumProp('GREY', 'GREEN', 'RED', 'ORANGE')
  private readonly color!: string;

  private get formattedTemperature(): string {
    return this.temperature === undefined ? 'XX' : formatMetricValue('temperature', this.temperature);
  }
}
