







import { Component, Vue } from 'vue-property-decorator';
import { AppActAquaAppViewEntryTreeNodeQuery } from './__generated__/AppActAquaAppViewEntryTreeNodeQuery';
import entryTreeNodeQuery from './entry-tree-node.gql';

@Component
export default class AppView extends Vue {
  private async created(): Promise<void> {
    const { data } = await this.$apollo.query<AppActAquaAppViewEntryTreeNodeQuery>({ query: entryTreeNodeQuery });
    const treeNodeId = data.treeNodes.first.id;

    this.$router.replace({ name: 'AppActAqua/TreeNode', params: { treeNodeId } });
  }
}
